import * as Sentry from "@sentry/nuxt"

// Config
const config = useRuntimeConfig().public

// Initialize Sentry
Sentry.init({
  debug: config.context === "dev",
  dsn: config.sentry.dsn,
  environment: config.context,
  tracesSampleRate: 1.0
})
